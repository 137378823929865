<template>
  <div class="techies-layout">
    <HeaderControllerSkeleton />
    <TopLiveDropControllerSkeleton />
    <LazyTechiesEndBlow v-if="isLost || isWon" :variant="blowVariant" />
    <main class="techies-layout__main">
      <div class="techies-layout__main-background">
        <NuxtImg
          v-if="changeMD(true, false)"
          class="techies-layout__main-background__left"
          :src="bgImages.left"
          provider="localProvider"
          loading="lazy"
        />
        <NuxtImg
          v-if="changeMD(true, false)"
          class="techies-layout__main-background__right"
          :src="bgImages.right"
          provider="localProvider"
          loading="lazy"
        />
      </div>
      <div class="techies-layout__main-page">
        <slot />
      </div>
    </main>
    <StatisticControllerSkeleton>
      <StatisticController
        class="techies-layout__statistic"
        :keys-show="[
          EStatisticItemVariants.OPENED,
          EStatisticItemVariants.USERS,
          EStatisticItemVariants.UPGRADES,
          EStatisticItemVariants.BATTLES,
          EStatisticItemVariants.ONLINE,
          EStatisticItemVariants.ARCANES,
        ]"
      />
    </StatisticControllerSkeleton>

    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
import { useInfoStore } from '~/project-layers/dota/features/techies/store/info.store';
import { EEndBlowVariants } from '~/features/techies/components/EndBlow/EndBlow.types';

import { EStatisticItemVariants } from '~/config/apps/index.types';

const infoStore = useInfoStore();
const { isLost, isWon } = storeToRefs(infoStore);

const changeMD = GlobalUtils.Media.changeByMedia('md');
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const blowVariant = computed(() => (isWon.value ? EEndBlowVariants.WIN : EEndBlowVariants.LOSE));

const bgImages = computed(() => ({
  left: getS3Image('techies', changeXL('bg-left', 'bg-left-m')),
  right: getS3Image('techies', changeXL('bg-right', 'bg-right-m')),
}));
</script>

<style lang="scss" scoped src="assets/layouts/techies.scss"></style>
